<template>
  <ion-page>
    <teleport to="head">
      <meta property="description" content="Pinoy SG Online Marketplace in Singapore">
    </teleport>
    <ion-content fullscreen> 
      <ion-grid class="ion-margin-top">
        <ion-row class="ion-justify-content-center">
          <ion-col size-lg="5" size-xs="12" size-sm="11">
          <div class="header">
            <div class="leftside">
              <router-link to="/">
                <img src="@/assets/img/sukisan-logo.png" class="imglogo" alt="Pinoy SG Room For Rent"/>
              </router-link>
              <ion-text>
                <router-link to="/">
                  <h1 class="ion-no-margin">Sukisan SG Pinoy Marketplace</h1>
                </router-link>
              <div>Filipino Room for Rent, Gadget Sell, Offer Services</div>
              </ion-text>
            </div>
            <div class="rightside">
              <span class="ion-margin" v-if="!userStore.isLoggedIn.value">
                <router-link to="/login">
                 Login
                </router-link>
              </span>
              <ion-icon :icon="personCircleOutline" class="icon-user" @click="openPopMenu"></ion-icon>
            </div>
          </div>
            <ion-searchbar @search="searchInput"></ion-searchbar>
            <ion-row>
              <ion-col class="ion-padding-start ion-padding-end text-content">Want to move into a new place? Find your <b>Pinoy Room for Rent</b> here. We carefully curate the available rooms from various sources. And you can post your own ads, too! Let's help our fellow <b>Filipinos in Singapore</b> find their new home.</ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-margin-bottom">
              <ion-col size-sm="3" size-xs="5"
                class="ion-text-center"
                v-for="menu in state.menu"
                :key="menu.title"
                >
                  <div class="menu-box">
                    <router-link :to="menu.url"><img
                      :src="menu.image"
                      :alt="menu.title"
                      width=60 height=60
                      class="menu-icon"
                    /></router-link>
                    <ion-card-title color="light" class="menu-title">
                      <router-link :to="menu.url">
                        {{ menu.title }}
                      </router-link>
                    </ion-card-title>
                    <ion-card-subtitle color="light" class="menu-subtitle">{{ menu.subtitle }}</ion-card-subtitle>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-margin-bottom bottomrow browse ion-justify-content-center">
                  <router-link to="/sg/all/"><span class="v-center">Browse all ads</span></router-link>
                  <router-link to="/sg/all/"><ion-icon :icon="arrowForward" class="arrow-forward"></ion-icon></router-link>
              </ion-row>
              <ion-row class="ion-margin-bottom bottomrow">
                <ion-col size-xs="12" size-sm="6">
                  <div class="text-content">Do you have something to sell or rent?</div>
                  <ion-button color="warning" class="post-ad" @click.prevent="addNewPost" name="post-ad-button"> Post an Ad</ion-button>
                </ion-col>
              </ion-row>
          </ion-col>
          <ion-col size-lg="6" size-xs="12" size-sm="11" class="listPost">
            <ion-row class="ion-justify-content-center ion-padding-bottom">
              <ion-col size="11" class="header ion-margin-top">Recent Ads <router-link :to="`/${countryCode}/all/`">See all</router-link></ion-col>
              <ion-col size="11"
                v-for="post in state.posts"
                :key="post.id"
                class="post"
                >
                <ad-item :item="post" size="mini"></ad-item>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonSearchbar, IonButton,
         IonCol, IonGrid, IonRow, IonText,
         IonCardTitle, IonCardSubtitle,
         IonContent, IonIcon, popoverController
     } from '@ionic/vue'
import { defineComponent, onMounted, reactive , 
         inject, onUpdated, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import axios from 'axios'
import imgProperty from '@/assets/img/property.svg'
import imgHandyman from '@/assets/img/handyman.svg'
import imgLorry from '@/assets/img/lorry.svg'
import { arrowForward, personCircleOutline } from 'ionicons/icons'
import userStore from '@/store/user'
import popMenuUSer from '@/components/popMenuUser.vue'
import axios from '@/utils/api'
import AdItem from '@/components/AdItem.vue'

export default defineComponent({
  name: "Home",
  components: { 
          IonPage, IonSearchbar, IonButton,
          IonCol, IonGrid, IonRow, IonText,
          IonCardTitle, IonCardSubtitle,
          IonContent, IonIcon, AdItem
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const loadSpin = inject('loadSpinner')
    const countryCode = route.params.countrycode || 'sg'
    const newAdUrl = `/${countryCode}/new/`
    let popMenu = null

    const state = reactive({
        layout: 'app-layout',
        posts: [],
        menu: [
         { 
           title: "Property",
           subtitle: "Rent Unit, Room, and Bedspace",
           image: imgProperty,
           description: "Find your next home and make new connection. Start from here.",
           url: `/${countryCode}/property/`
         },
         { 
           title: "Handyman",
           subtitle: "Handyman Service",
           description: "If you need plumber, carpenter, locksmith, or just about any help, find them here.",
           image: imgHandyman,
           url: `/${countryCode}/handyman/`
         },
         {
           title: "Lorry",
           subtitle: "Moving",
           description: "Whether you are moving houses or need IKEA delivery, help is here.",
           image: imgLorry,
           url: `/${countryCode}/lorry/`
         },
        ]
    })
    onMounted(() => {
      const api = `/${countryCode}/p/all/?limit=6`
      axios.get(api)
      .then(res => {
        state.posts = res.data.result
        // console.log(state.posts.length)
      })
      .catch(e => {
        console.log(e)
      })
    })

    onUpdated(() => {
      nextTick(() => loadSpin.status = false)
    })

    function changeLayout(layout) {
      ctx.emit('update:state.layout', layout)
      // console.log(layout)
    }

    function searchInput(evt) {
      const queryInput = encodeURI(evt.target.value)
      console.log(queryInput)
      router.push({
        name: 'listPosts',
        params: {
          countrycode: countryCode,
          category: 'all'
        },
        query: {
          q:queryInput
        }
        })
    }
   
    function dismissPopover() {
      popMenu.dismiss()
      console.log('dismiss')
    }

    async function openPopMenu(ev) {
      const popover = await popoverController
        .create({
          component: popMenuUSer,
          cssClass: 'my-custom-class',
          event: ev,
          translucent: true
        })
      popMenu = popover
      return popover.present();
    }

    function addNewPost() {
      router.push(newAdUrl)
    }

    return { state, changeLayout, 
             arrowForward, 
             personCircleOutline, 
             searchInput,
             userStore,
             openPopMenu,
             dismissPopover,
             addNewPost,
             countryCode
           }
  }
})
</script>
<style scoped>
.v-center {
  line-height: 16px;
  display: inline;
  margin-right: 8px;
}

.post-ad {
  width:100%;
  text-transform: initial;
  letter-spacing: initial;
  --border-radius: 10px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-start: 50px;
  --padding-end: 50px;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}
.menu-icon {
  width: 60px;
}
.menu-box {
  background: #279E6D !important;
  border-radius: 8px;
  height:100%;
  width:100%;
  padding: 10px;
  font-size: 12px;
}
.menu-title {
  font-size: 14px;
  margin: 5px;
}
.menu-subtitle {
  font-size: 12px;
  color: #cccccc;
}
.text-content {
  margin-bottom: 5px;
  font-size: 12px;
}

ion-item {
    --border-width:2px;
    --border-radius: 10px;
    --background: #cccccc;
}
ion-content {
  --background: #2db282;
  --color: var(--ion-color-primary-contrast) !important;
  font-size:14px;
}
ion-searchbar {
  --background: #228F6E !important;
  --border-radius: 10px !important;
  --color:whitesmoke !important;
  --icon-color: whitesmoke !important;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}
.header {
  display:flex;
  justify-content: space-between;
  /* margin-top:5%; */
  padding: 0px 10px 0px 10px;
}
.header > div {
  display: flex;
  align-items: center;
}
.header ion-text {
  align-items: center;
  align-self: flex-start;
}
h1 {
  font-weight: 700;
  font-size: 20px;
}
.arrow-forward {
  font-size:16px !important;
}
.icon-user {
  font-size: 32px !important;
}

  a {
    text-decoration: none;
    color:var(--ion-color-primary-contrast);
  }

.listPost {
  background: #ffffff;
  color: var(--ion-color-primary-text-dark);
  border-radius: 20px;
}

.listPost a {
  color: var(--ion-color-primary-text-dark);
}

.browse {
  display: flex;
  background: #279E6D !important;
  border-radius: 8px;
  align-items: center;  
  padding: 10px;
  font-size: 12px;  
}
.bottomrow {
  margin: 0px 8px;
}
.imglogo {
  width: 50px;
  min-width: 50px;
  margin-right: 10px;
  border-radius: 50%;
}
</style>