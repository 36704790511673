import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Home from '@/pages/Home.vue'
/* eslint-disable */
// @ts-ignore
import userStore from '@/store/user.js'
/* eslint-enable */

// import CardCreate from '@/pages/AdCreate.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    redirect: '/sg/'
  },
  {
    name: 'userDashboard',
    path: '/user/dashboard',
    component: () => import('@/pages/User/UserDashboard.vue'),
    meta: { requiresAuth: true }    
  },  
  {
    path: '/login',
    component: () => import('@/pages/Login.vue'),
    meta: { layout: "basic" }
  },
  {
    path: '/register',
    component: () => import('@/pages/Register.vue')
  },
  {
    path: '/main2',
    component: () => import('@/views/MainMenu.vue')
  },
  {
    path: '/:countrycode/new/',
    component: () => import('@/pages/AdCreateFirst.vue')
  },
  {
    path: '/:countrycode/new/:category/',
    component: () => import('@/pages/AdCreateFirst.vue')
  },
  {
    name: 'createComplete',
    path: '/:countrycode/complete/:id/',
    component: () => import('@/pages/AdCreateSecond.vue'),
    props: true
  },
  {
    name: 'itemDetail',
    path: '/:countrycode/:category/:id/',
    component: () => import('@/pages/AdDetail.vue'),
    props: true
  },
  {
    name: 'listPosts',
    path: '/:countrycode/:category/',
    component: () => import('@/pages/AdList.vue')
  },
  {
    name: 'Chat',
    path: '/chat',
    component: () => import('@/pages/ChatIO.vue'),
    meta: { requiresAuth: true }
  },  
  {
    path: '/:countrycode',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log('from:')
  // console.log(from)
  // console.log('to:')
  // console.log(to)
  // const loading = loadSpin.status
  // loadSpin.status = true
  // console.time(to.fullPath)
  await userStore.refreshToken()
  // console.timeEnd(to.fullPath)
  // loadSpin.status = loadSpin.status && loading
  // next()
  // console.log(r)
  // console.log(userStore.isLoggedIn)
  // console.log(to.matched)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!userStore.isLoggedIn.value) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }

})


export default router
