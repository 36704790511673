<template>
  <ion-app class="app">
    <spinner :spin="spin.status"></spinner>
    <!-- component v-bind:is="state.layout" ></component -->
    <ion-router-outlet/>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, reactive, provide, watch } from 'vue';
import Spinner from '@/components/Spinner.vue'
// import MainMenu from '@/views/MainMenu.vue'
// import Basic from '@/views/BasicLayout.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Spinner
    // 'app-layout': MainMenu,
    // 'plain-layout': Basic
  },
  setup() {
    const route = useRoute
    const state = reactive({
      spin: false,
      layout: 'plain-layout'
    })
    const spin = reactive({
      status: false
    })    
    provide('loadSpinner', spin)
   
    // watch(spin, spin => {
    //   console.log(
    //     `The new spin status is ${spin.status}`
    //   )
    // })

    return { state, route, spin}
  }
});
</script>
