<template>
    <ion-item class="ion-no-padding ion-align-items-start">
      <img :src="item.thumbnail"  :alt="`${item.title} thumbnail`" 
      :class="[{ 'mini': mini }, 'img_thumb']" width=100 height=100 slot="start" @click="showItem"/>
      <div class="item_descbox">
        <ion-card-title :class="[{ 'mini': mini }, 'item_title']">
          <!-- <router-link :to="`/${countryCode}/${item.category.code}/${item.id}/`"> -->
          <router-link :to="`/${countryCode}/${category}/${item.id}/`">
            {{ item.title }}
          </router-link>    
        </ion-card-title>
        <div class="item_desc" v-if="!mini">
          {{ item.location }}<br/>
          <!-- span v-for="(mrt, index) in item.nearest_mrt" :key="index">{{ mrt.name }}</span><br/ -->
        </div>
        <div class="item_footer">
            <ion-card-title :class="[{ 'mini': mini }, 'item_price' ]">${{ item.price }}</ion-card-title>
            <span :class="{ 'mini': mini }">{{ state.created }}</span>
        </div>
      </div>
    </ion-item>
</template>

<script>
import { defineComponent, computed, reactive, onMounted } from 'vue'
import { parseISO, formatDistance } from 'date-fns'
import { // IonGrid, IonRow, IonCol, 
         IonCardTitle, IonItem,
       } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'AdItem',
  props: {
    item: Object,
    size: {
      type: String,
      default: 'normal'
    }
  },
  components : {
    // IonGrid, IonRow, IonCol,
    IonCardTitle, IonItem
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const countryCode = route.params.countrycode || 'sg'
    const category = route.params.category || 'all'
    const it = computed(() =>  props.item)
    const mini = computed(() => props.size === 'mini')
    const state = reactive({
      created: ""
    })

    function showItem() {
      // console.log(JSON.stringify(props.item))
       router.push({ 
          name: 'itemDetail', 
          params: {
            id: props.item.id,
            category: category,
            countrycode: countryCode,
            item: JSON.stringify(props.item)
          }
        })
    }

    onMounted (() => {
      const itemcreated = props.item.created
      if (itemcreated) {
        // state.created = formatDistance(Date(itemcreated), new Date())
        state.created = formatDistance(parseISO(itemcreated), new Date());
      } else {
         state.created = ""
      }
    })

    return { it, mini, showItem, state, category, countryCode }
  }
})
</script>

<style scoped>
ion-item {
  _background: linear-gradient(180deg, rgba(235,249,243,1) 0%, rgba(231,245,241,1) 17%, rgba(242,248,247,1) 100%);
  --background: #F2F8F7 !important;
  --highlight-height: 0px;
  border-radius: 10px;
  --border-style: none;
  height: 100%;
  position: relative;
  color: var(--ion-color-primary-text-dark);

}
ion-card-title {
  display: inline-block;
  color: var(--ion-color-primary-text-dark);
  font-size:18px;
  padding: 5px;
}

.align_end {
  display:inline-block;
}

.item_thumb {
  max-width: 15vw;
}

.img_thumb {
  display: inline-block;
  margin: 0px 5px 0px 0px;
  max-height: 15vh;
}

.item_thumb img{
  display: inline-block;
  max-height: 15vh;
}
.item_thumb:hover, .item_title:hover, .img_thumb:hover {
  cursor: pointer;
}
.ad-item {
  height:100%;
}
.post img {
  border-radius: 10px;
}
.item_price {
  font-size: 0.9rem;
}
.item_descbox {
  height: 100%;
}
.item_desc {
  font-size: 0.8rem;
  color: grey;
  margin-left: 5px;
}
.mini {
  font-size: 0.8rem !important;  
}
.item_title {
  font-size: 1rem;
  font-weight: 500;
}
.item_title a {
  color: black;
}
img.mini {
  margin: 0px 3px 0px 0px !important;
  max-height: 10vh !important;
}

.item_footer {
  display:flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  padding-right: 5px;
  align-self:flex-end;
  color: gray;
  font-size: 0.8rem;
}
.item_footer span:last-child {
  align-self:flex-end;
  margin-left: auto;
  padding-bottom: 2px;
  padding-right: 5px;
}

ion-card-title a {
  color: var(--ion-color-primary-text-dark);
}
</style>