import { reactive, computed, readonly } from 'vue'
import jwt_decode from 'jwt-decode' // eslint-disable-line 
// import toDate from 'date-fns/toDate'  
import differenceInMinutes from 'date-fns/differenceInMinutes'
import firebase from 'firebase/app'
import 'firebase/auth'
import { openToast } from '@/utils'
import axios from '@/utils/api'

import { setRoomId } from '@/store/chat.js'

const state = reactive({
  uid: localStorage.getItem('user_uid') || "",
  displayName: "",
  backendUser: {},
  isLoggedIn: false,
  accessToken: localStorage.getItem('access_token') || "",
  exp: 0,
  userComplete: false
})



function setLoggedIn(status) {
  state.isLoggedIn = status
}

function setAccessToken(aToken) {  
  state.isLoggedIn = true
  state.accessToken = aToken.access_token
  const jwt = jwt_decode(state.accessToken)
  // console.log(`token: ${jwt}`)
  // console.log(jwt)

  state.exp = jwt.exp * 1000 // convert into millisecond
  state.userComplete = jwt.completed
  state.backendUser.name = jwt.name
  state.backendUser.picture = jwt.picture

  localStorage.setItem('access_token', aToken.access_token)
  localStorage.setItem('ws_token', aToken.access_token)
  localStorage.setItem('logged-in', state.isLoggedIn)
  localStorage.setItem('token_type', aToken.token_type)
  localStorage.setItem('exp', state.exp) 
  localStorage.setItem('user_complete', state.userComplete)
  
  // todo: ws_token should have a different value
  const d = new Date()
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie =
    "ws_token=" + state.accessToken + ";" + expires + ";path=/" 


  const currentUser = firebase.auth().currentUser
  // console.log('setAccessToken')
  if (currentUser  != null) {
    localStorage.setItem('user_uid', currentUser.uid)
    state.uid = currentUser.uid
    // setRoomId(currentUser.uid)
    state.displayName = currentUser.displayName || currentUser.email
    // console.log(JSON.stringify(currentUser))
  }
}

async function setUserDetails(displayName, contactNo, photoURL) {
  const user = firebase.auth().currentUser
  if (user != null) {
    user.updateProfile({
      displayName: displayName || user.displayName,
      photoURL: photoURL || user.photoURL,
      phoneNumber: contactNo || user.contactNo
    }).then(function() {
      // Update successful.
      console.log('Customer info updated')
    }).catch(function(error) {
      // An error happened.
      console.log(`Error updating customer info: ${error.message}`)
    })
  }
}

async function setIdToken(displayName, contactNo) {
  let idToken = ""
  let retval = true
  try {
    idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
  } catch(error) {
    openToast(error.message, "Authentication", "Warning", "middle", "danger" )
    return false
  }
  if (!idToken) {
    openToast("Unable to set access token", "Authentication", "Warning", "middle", "danger" )
    return false
  }
  // console.log(`idToken: ${idToken}`)
  try {
    const tokenData = await axios.get('/auth/verifytoken', 
          {
            headers: { 
              'X-Id-Token': idToken,
              'X-Display-Name': displayName || "",
              'X-ContactNo': contactNo || ""
            }
        })
    console.log('tokenData')
    setAccessToken(tokenData.data)
  } catch (error) {
    openToast(error.message, "Authentication", "Warning", "middle", "danger" )
    retval = false
  }
  return retval
}

function logoutUser(){
  console.log('clearing up user details...')
  firebase.auth().signOut().then(function() {
    // Sign-out successful.
  }).catch(function(error) {
    // An error happened.
    console.log(error)
  })
  
  state.isLoggedIn = false
  state.uid = ""
  state.name = ""
  state.loggedUser = {}
  state.accessToken = ""
  state.exp = state.exp - (3600 * 24 * 30 * 1000)
  localStorage.removeItem('access_token')
  localStorage.removeItem('logged-in')
  localStorage.removeItem('token_type')
  localStorage.removeItem('exp')
  localStorage.removeItem('user_complete')
  localStorage.removeItem('user_uid')
}

function setUser(user) {
  // console.log('setting user')
  console.log(JSON.stringify(user))
  state.uid = user.uid
  state.displayName = user.displayName
}

async function refreshToken() {
  let retval = false
  const tokenRefresh = process.env.VUE_APP_TOKEN_REFRESH || 5
  const tokenExpiry = process.env.VUE_APP_TOKEN_EXPIRY || 0
  state.exp = parseInt(localStorage.getItem('exp')) || 1606832027865
  const timeDiff = differenceInMinutes(new Date(state.exp), new Date())

  const currentUser = firebase.auth().currentUser
  // state.currentUser = currentUser
  // console.log(currentUser)
  // console.log(`token Validity: ${timeDiff}; tokenExpiry: ${tokenExpiry}; tokenRefresh: ${tokenRefresh}` )
  // console.log(`state.accessToken: ${state.accessToken}`)
  if (state.accessToken != "") {
    setAccessToken({ access_token: state.accessToken }) // eslint-disable-line
    retval = true
  } else {
    // console.log('uhmnn, no token found')
    retval = false
  }
  if (currentUser && timeDiff < tokenRefresh && timeDiff > tokenExpiry) {
    // console.log('refreshing token')
    await setIdToken()
    retval = true
    // localStorage.getItem('access_token') || ''
  } else if (currentUser && timeDiff < tokenExpiry) {
    console.log('expired token, clearing now.')
    logoutUser()    
    retval = false
  }
  // console.log('retval exit')
  return retval

}

// loading mechanism
if (state.accessToken) {
  state.isLoggedIn = localStorage.getItem('logged-in') || false
  state.userComplete = localStorage.getItem('user_complete') || false
}



const store = {
  isLoggedIn: computed(() => state.isLoggedIn),
  // currentUser: readonly(state.currentUser),
  // backendUser: readonly(state.backendUser),
  // accessToken: readonly(state.accessToken),
  setLoggedIn,
  setIdToken,
  setAccessToken,
  setUserDetails,
  refreshToken,
  logoutUser,
  setUser, 
  user: readonly(state),
  state: readonly(state) //compatibility,
}

export default store

