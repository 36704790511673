import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import firebase from 'firebase/app';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './registerServiceWorker'

/* Theme variables */
// import './theme/tailwind.css';
import 'fontsource-noto-sans-kr';
import './theme/variables.css';

 // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIRE_APIKEY,
  authDomain: process.env.VUE_APP_FIRE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIRE_DATABASEURL,
  projectId: process.env.VUE_APP_FIRE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIRE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIRE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIRE_APPID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const app = createApp(App)
  .use(IonicVue)
  .use(router);

app.config.isCustomElement = tag => tag.startsWith('ion-')

router.isReady().then(() => {
  app.mount('#app');
});