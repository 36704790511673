<template>
  <ion-content class="ion-padding-start ion-padding-end">
    {{ currentUser.displayName }}
    <ion-list class="ion-no-padding">
      <!-- ion-list-header>Profile</ion-list-header -->
      <ion-item 
        button
        detail=false
        lines="none"
        v-if="!userStore.isLoggedIn.value"
        @click.prevent="doLogin()"
        class="ion-no-padding"
        >
        <ion-icon :icon="logInOutline" slot="start"></ion-icon>
        Login
      </ion-item>
      <ion-item 
        button
        detail=false
        lines="none"
        v-if="!userStore.isLoggedIn.value"
        @click.prevent="doRegister()"
        class="ion-no-padding"
        >
        <ion-icon :icon="personAddOutline" slot="start"></ion-icon>
        Register
      </ion-item>
      <ion-item 
        button
        detail=false
        lines="none"
        v-if="userStore.isLoggedIn.value"
        @click.prevent="showDashboard()"
        class="ion-no-padding"
        >
        <ion-icon :icon="personOutline" slot="start"></ion-icon>
        Dashboard
      </ion-item>
      <ion-item 
        button
        detail=false
        lines="none"
        v-if="userStore.isLoggedIn.value"
        @click.prevent="showMessages()"
        class="ion-no-padding"
        >
        <ion-icon :icon="mailOutline" slot="start"></ion-icon>
        Messages
      </ion-item>
      <ion-item 
        button
        detail=false
        lines="none"
        v-if="userStore.isLoggedIn.value"
        @click.prevent="doLogout()"
        class="ion-no-padding"
        >
        <ion-icon :icon="exitOutline" slot="start"></ion-icon>
        Logout
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonList, IonItem,
         IonIcon, 
        //  IonListHeader, 
         popoverController,
         } from '@ionic/vue'
import { defineComponent, reactive, readonly } from 'vue'
import userStore from '@/store/user'
import chatStore from '@/store/chat'
import { useRouter, useRoute } from 'vue-router'
import { exitOutline, personOutline, personAddOutline, logInOutline,
  mailOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'popMenuUser',
  components: { 
    IonContent, IonList, IonItem, 
    IonIcon, 
    // IonListHeader 
    },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      test: ""
    })
    const currentUser = userStore.user

    function doLogin() {
      popoverController.dismiss()
      console.log(route)
      console.log(`route: ${route.fullPath}`)
      router.push({
        path: '/login',
        query: { redirect : route.fullPath }
      })
    }

    function doRegister() {
      popoverController.dismiss()
      router.push('/register')
    }
    
    function doLogout() {
      userStore.logoutUser()
      popoverController.dismiss()
    //   router.push('/login')
    }

    function showMessages() {
      // clear room id
      chatStore.state.roomId =""
      popoverController.dismiss()
      router.push('/chat')
    }
    function showDashboard() {
      router.push({name: 'userDashboard'})
      popoverController.dismiss()
    }

    return {
      userStore,
      state,
      doLogin,
      doLogout,
      doRegister,
      showDashboard,
      showMessages,
      exitOutline, personOutline, logInOutline,
      personAddOutline,
      mailOutline, 
      currentUser,
      router
    }
  }
})

</script>
<style scoped>
ion-icon {
  margin:0px 10px 0px 0px;
}
ion-item {
  --inner-padding-bottom:0px;
  --inner-padding-end:0px;
  --inner-padding-start:0px;
  --inner-padding-top:0px;
}
</style>