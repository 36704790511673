import { toastController } from '@ionic/vue'
import axios from 'axios'

export async function openToast(msg, cls, header, pos, color) {
  const toast = await toastController
    .create({
      message: msg,
      header: header,
      position: pos,
      duration: 2000,
      color: color
    })
  return toast.present();
}

export async function getOneMapURL(post) {
  // console.log(post)
  return new Promise(resolve => {
    if ('longitude' in post && 'latidue' in post) {
      const mapurl = `https://developers.onemap.sg/commonapi/staticmap/getStaticImage?layerchosen=original&lng=${post.longitude}&lat=${post.latidude}&zoom=17&height=512&width=512`
      resolve(mapurl)
    } else if ('postal_code' in post && 'location' in post) {
      const searchVal = encodeURI(post.postal_code) || encodeURI(post.location) || encodeURI("Marina Bay Sands")
      axios
      .get(`https://developers.onemap.sg/commonapi/elastic/omsearch?searchVal=${searchVal}&returnGeom=Y&getAddrDetails=Y`)
      .then(res => {
        const onemap = res.data.results[0]
        // console.log(onemap)
        const mapurl = `https://developers.onemap.sg/commonapi/staticmap/getStaticImage?layerchosen=original&lng=${onemap.LONGITUDE}&lat=${onemap.LATITUDE}&zoom=17&height=512&width=512`
        resolve(mapurl)
      })  
    } else {
      resolve("")
    }
  })
}
