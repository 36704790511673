<template>
   <div class="content" v-if="spin.status">
      <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <!-- ion-spinner name="crescent" color="primary"></ion-spinner -->
   </div>
</template>

<script>
// import { IonSpinner } from '@ionic/vue'
import { defineComponent, computed, inject } from 'vue'

export default defineComponent({
  name: 'Spinner',
//   components: {
//     IonSpinner
//   },
  setup() {
    const spin = inject('loadSpinner')
    return { spin }
  }
})
</script>

<style scoped>
.content {
  z-index: 100;
  display: flex;
  height:100vh;
  width: 100vw;
  background-color: rgba(0, 0,0, 0.2);
  justify-content: center;
  align-items: center;
}
ion-spinner {
  z-index: 100;
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  background-color: var(--ion-color-primary-tint);
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  background-color: var(--ion-color-secondary-tint);
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  background-color: var(--ion-color-warning);
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

</style>