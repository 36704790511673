import { reactive, readonly } from "vue"
import userStore from '@/store/user.js'
import io from "socket.io-client"
// import { currentUrlWithPortNumber } from "../utils/url-manipulation.js";
import { 
  parseISO, 
  formatDistanceToNow, 
  differenceInCalendarDays, 
  format 
} from 'date-fns'

export function useSocketIo() {
    return io(process.env.VUE_APP_CHAT_URL, 
      {
        path: '/ws/socket.io',
      }
    )
}

function getTS(timestamp) {
    if (!timestamp ){
      return ""
    }
    const ts = parseISO(timestamp)
    const currentTime = new Date()
    const d = differenceInCalendarDays(ts, currentTime)
    if (d > 7) {
      return format(ts, 'MM/dd/yyyy')
    }
    else if (d > 1) {
      return formatDistanceToNow(ts, {includeSeconds: false})
    } else {
      return formatDistanceToNow(ts, {includeSeconds: true})
      // return format(ts, 'H:mm:ss')
    }
}

const messages = reactive([])
const rooms = reactive([])

const state = reactive({
  roomId: ""
})

export function useSocketName(socket) {
  socket.on("message", msg => {
    // console.log(msg)
    const obj = JSON.parse(msg)
    obj.ts = function() {
      // const ts = parseISO(this.timestamp)
      // const currentTime = new Date()
      // const d = differenceInCalendarDays(ts, currentTime)
      // if (d > 7) {
      //   return format(ts, 'MM/dd/yyyy')
      // }
      // else if (d > 1) {
      //   return formatDistanceToNow(ts, {includeSeconds: false})
      // } else {
      //   // return formatDistanceToNow(ts, {includeSeconds: true})
      //   return format(ts, 'H:mm:ss')
      // }
      // return "test mie"
      return getTS(obj.timestamp)
    }
    // const ownMessage = userStore.user.uid === obj.userid
    obj.ownMessage = userStore.user.uid === obj.userid
    // console.log(`ownMessage: ${obj.ownMessage}| ${obj.userid} vs ${userStore.user.uid}`)
    // obj.ts = computed(() => formatDistanceToNow(parseISO(obj.timestamp), {includeSeconds: true}))
    // console.log(JSON.stringify(obj))
    messages.push(obj)
    // console.log(messages.length)
  })

  socket.on("joinRoom", msg => {
    // console.log(msg)
    const obj = JSON.parse(msg)
    console.log(`joinRoom: ${msg}`)
    if (obj.roomid == obj.userid) {
      rooms.length = 0
      obj.rooms.forEach(room => {
        rooms.push(
          {
            roomid: String(room.roomid),
            user: room.users.find(u => u.uid != obj.userid)
          }
          )
      })
    }
    // refresh the message if user re-enters a room; should not affect other customers;
    if (obj.userid === userStore.user.uid){
      messages.length = 0
      messages.push(...obj.messages.map(msg => {
        return {
          ...msg, 
          ownMessage: userStore.user.uid === msg.userid,
          ts: function() {
            return getTS(msg.timestamp)
          }
        }
      }))
    }
    console.log(`messages: ${JSON.stringify(messages)}`)
  })

  socket.on("newroom", msg => {
    const obj = JSON.parse(msg)
    const room = {
      roomid: obj.roomid
    }
    rooms.push(room)
  })

  function sendMessage(msg) {
    // console.log(msg)
    socket.emit("message", 
      JSON.stringify(
        {
          roomid: msg.roomid,
          userid: msg.userid,
          username: msg.userName || msg.userid,
          message: msg.message
        }
      )
    )
  }
  
  function joinRoom(userid, roomid, username) {
    // console.log(socket)
    // console.log(`${userid} is joining room ${roomid}`)
    messages.length = 0
    socket.emit('joinRoom', 
      JSON.stringify(
        {
          roomid: String(roomid),
          userid: String(userid),
          username: username || userid
        })
      )
    }

   function PCSignaling(event, options) {
      socket.emit(event, options)
    //   this.$socket.emit(conference ? WS_EVENTS.PCSignalingConference : WS_EVENTS.privateMessagePCSignaling, {
    //         desc: desc,
    //         to: to,
    //         from: state.username,
    //         room: room,
    //   })
   }


  return [sendMessage, joinRoom, rooms, messages, PCSignaling ]
}

const setRoomId = async (roomId) => {
  const idx = rooms.findIndex(obj => obj.roomid == roomId)
  console.log(`idx: ${idx}`)
  if (idx == -1) {
    rooms.push(
      { 
        roomid: roomId,
        user: { uid: "new"+roomId, name: "new chat" }
      }
    )
  }
  state.roomId = roomId
}

export default {
  messages,
  rooms,
  setRoomId, 
  state: readonly(state)
}

export { rooms, messages }